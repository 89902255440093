import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo';
import Layout from '../components/layout';
import TextBanner from '../components/text-banner/text-banner';
import OverlayBlockTeam from '../components/overlay-block-team/overlay-block-team';
import StaffMembersBlock from '../components/staff-block/staff-block';

class MeetTheTeam extends React.Component {
    render() {
        const teamTextBanner = get(this, 'props.data.teamTextBanner');
        const teamOverlayBlockTeam = get(this, 'props.data.teamOverlayBlockTeam');
        const teamStaffMembersBlock = get(this, 'props.data.teamStaffMembersBlock');
        return (
            <Layout location={this.props.location}>
                <Seo title="Meet The Team"/>
                    <div className="container-fluid container-fluid--page">
                        <div className="meet-team">
                            <TextBanner textOne={teamTextBanner.textOne} textTwo={teamTextBanner.textTwo} />
                            <OverlayBlockTeam image={teamOverlayBlockTeam.backgroundImage.url} headingText={teamOverlayBlockTeam.headingText}
                                descriptionText={teamOverlayBlockTeam.descriptionText} boxOneNumber={teamOverlayBlockTeam.boxOneNumber}
                                boxOneText={teamOverlayBlockTeam.boxOneText} boxTwoNumber={teamOverlayBlockTeam.boxTwoNumber}
                                boxTwoText={teamOverlayBlockTeam.boxTwoText} />
                            <StaffMembersBlock members={teamStaffMembersBlock.nodes} />
                        </div>
                    </div>
            </Layout>
        )
    }
}

export default MeetTheTeam;

export const query = graphql`
query MeetTheTeam {
    teamTextBanner: contentfulTextBanner(textBannerName: {eq: "teamTextBanner"}) {
        textBannerName
        textOne
        textTwo
    }
    teamOverlayBlockTeam: contentfulOverlayBlockTeam(overlayBlockName: {eq: "teamOverlayBlockTeam"}) {
        overlayBlockName
        headingText
        descriptionText
        boxOneNumber
        boxOneText
        boxTwoNumber
        boxTwoText
        backgroundImage {
            url
        }
    }
    teamStaffMembersBlock: allContentfulStaffMembersBlock {
        nodes {
            image {
                url
            }
            name
            description
        }
    }
}
`
